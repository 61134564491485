import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import App from './App';
import { BrowserRouter } from "react-router-dom";
import { MoralisProvider } from "react-moralis";
const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider
      appId="oPWCXqOdu8zVvIZCBa4NQ8xzOJ5lF7sqSnFu08Cn"
      serverUrl="https://cbxotxvuje1m.moralis.io:2053/server"
    >
      <BrowserRouter>
        <Suspense fallback={<div className=""></div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
